/* eslint-disable */
<template>
  <v-container class="fntp-container" id="carbon-emmision" fluid tag="section">

    <!--

    <v-row class="top-header-flex" style="justify-content: center;">

      <div style="width:80%;display:flex">
        <v-col>
          <a href="https://acteurspourlaplanete.fntp.fr/">
            <v-img
                   id="fntp-image"
                   height="60"
                   width="159"
                   src="/icons/svg/fntp-logo.svg"
            >
            </v-img>
          </a>
        </v-col>

      </div>
    </v-row>
    <v-row class="bottom-header-flex" style="height:144px;" >
      <span style="font-family: Dosis, sans-serif;
          font-size: 46px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;
          text-transform: uppercase;
          align-self: center;
          padding-left: 80px;
          z-index:1;
      ">Calculateur CO<sub>2</sub> - Omega TP</span>
      <div style="position: absolute; right: 0px;">
        <v-img
                height="199"
                width="350"
                src="/icons/svg/dragees_titre.svg"
        >
        </v-img>
      </div>
    </v-row>

    -->


    <!-- astuce pour résoudre problème graphique d'update -->
  <div style="display:none" v-if="update"></div>

  <v-dialog
    v-model="dialog_load"
    width="1000"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
      <h2 class="mb-4 font-weight-bold">Attention</h2>
      </v-card-title>

      <v-card-text>
        Certaines de vos données n’ont pas pu être affichées en raison d’une mise à jour dans les facteurs d'émissions associés au calculateur.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
        @click="dialog_load = false;"
        >
        Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog v-model="dialog_entreprise" width="80%" max-width="800px">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        <h2 class="mb-4 font-weight-bold">Mon Entreprise</h2>
      </v-card-title>

      <v-row>
        <v-col cols="6">
          <v-card-text>Entreprise</v-card-text>

          <v-row style="padding:0px 30px 0px 30px">
              <v-text-field label="Nom" v-model="entreprise['nom']"/>&nbsp;
          </v-row>

          <v-row style="padding:0px 30px 0px 30px">
            <v-text-field label="N° SIREN" v-model="entreprise['siren']"/>&nbsp;
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-card-text>Adresse</v-card-text>

          <v-row style="padding:0px 30px 0px 30px">
            <v-text-field label="N° et Rue" v-model="entreprise['adresse_rue']"/>&nbsp;
          </v-row>

          <v-row style="padding:0px 30px 0px 30px">
            <v-col cols="6" style="padding:0px 10px 0px 0px" > <v-text-field label="Code Postal" v-model="entreprise['adresse_CP']"/>&nbsp;</v-col>
            <v-col cols="6" style="padding:0px 0px 0px 10px" > <v-text-field label="Ville" v-model="entreprise['adresse_ville']"/>&nbsp;</v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card-text>
        Merci de renseigner correctement les informations (SIREN notamment), car celles-ci ne seront pas vérifiées avant la création du PDF. Si toutefois vous voulez modifier le texte généré, il sera toujours possible de générer de nouveau le PDF associé. Vous pouvez retrouver votre numéro de SIREN sur <a href='https://www.fntp.fr/outils/annuaire-carte-professionnelle' target='_blank'>l’annuaire carte professionelle FNTP</a>
      </v-card-text>

      <v-divider></v-divider>
      <v-row>
        <v-col>
          <div style="padding-top:25px !important; padding-left:20px  !important" v-if="entreprise['nom'] == '' || entreprise['adresse_rue'] == '' || entreprise['adresse_CP'] == '' || entreprise['adresse_ville'] == ''"> l'Adresse et le nom d'entreprise son obligatoires</div>
        </v-col>
        <v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="entreprise['nom'] == '' || entreprise['adresse_rue'] == '' || entreprise['adresse_CP'] == '' || entreprise['adresse_ville'] == ''" color="primary" text @click="dialog_entreprise = false; set_cookie_entreprise(); GenerateReport()" >Exporter</v-btn>
        </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>


  <v-row style="justify-content: center;">

    <div class="main_prez" style="width:85%;max-width:1100px">


      <v-card style="padding:20px">

        <v-row>

          <v-col cols="2.4" >
            <div v-if="grandTotal.affichage" class="div_total" style="text-align:center;padding:5px;margin-top:-5px;font-weight:bold">
              <span class="text-normal" style="font-weight:bold" >{{grandTotal.label}} {{ grandTotal.affichage.substring(0, grandTotal.affichage.lastIndexOf(' ')) }} {{ grandTotal.affichage.substring(grandTotal.affichage.lastIndexOf(' '),grandTotal.affichage.length) }}CO<sub>2</sub>eq</sup></span>
            </div>
            <div v-else class="div_total" style="text-align:center;padding:5px;margin-top:-5px;font-weight:bold">
              <span class="text-normal" style="font-weight:bold" >{{grandTotal.label}} 0 kgCO<sub>2</sub>eq</sup></span>
            </div>
          </v-col>

        <!--
          <v-col cols="2.4" >
            <div v-if="grandTotal.affichage" class="div_total" style="text-align:center;padding:10px">
              <span class="gros-text" style="width:100%;font-weight:bold">{{ grandTotal.affichage.substring(0, grandTotal.affichage.lastIndexOf(' ')) }}</span>
              <div class="text-normal" style="font-weight:bold"  >{{ grandTotal.affichage.substring(grandTotal.affichage.lastIndexOf(' '),grandTotal.affichage.length) }}CO<sub>2</sub>eq</sup></div>
            </div>
            <div v-else class="div_total" style="text-align:center;padding:10px">
              <span class="gros-text" style="width:100%;font-weight:bold">0</span>
              <div class="text-normal" style="font-weight:bold" >kgCO<sub>2</sub>eq</div>
            </div>
            <div class="div_total" style="text-align:center;padding:5px;margin-top:-5px;font-weight:bold">
              <span class="text-normal" style="font-weight:bold"  >{{grandTotal.label}}</span>
            </div>
          </v-col>

          <v-col cols="2.4"  v-for="total in totaux" :key="total.index">
            <div v-if="total.affichage" class="div_poste" style="text-align:center;padding:10px">
                <span class="gros-text" style="width:100%;font-weight:bold">{{ total.affichage.substring(0, total.affichage.lastIndexOf(' ')) }}</span>
                <div class="text-normal" style="font-weight:bold"  >{{ total.affichage.substring(total.affichage.lastIndexOf(' '),total.affichage.length) }}CO<sub>2</sub>eq</sup></div>
            </div>
            <div v-else class="div_poste" style="text-align:center;padding:10px">
              <span class="gros-text" style="width:100%;font-weight:bold">0</span>
              <div class="text-normal" style="font-weight:bold" >kgCO<sub>2</sub>eq</div>
            </div>
            <div class="div_poste" style="text-align:center;padding:5px;margin-top:-5px;font-weight:bold">
                <span class="text-normal" style="font-weight:bold"  >{{total.label}}</span>
            </div>
          </v-col>

          -->
        </v-row>

        <div style="text-align:right">
          <v-btn
          class="bouton"
          text
          @click="EffaceSaisie"
          >
          Effacer ma saisie
          </v-btn>
        </div>


        <v-expansion-panels
          :disabled="disabled"
          multiple
        >

          <v-expansion-panel v-for="poste in mes_postes" class="panel" style="margin:5px;width:100%" >

            <v-expansion-panel-header class="panel" hide-actions >

              <template v-slot:default="{ open }">
                <v-col cols="6" style="padding:0px 0px 0px 10px;" class="text-moyen"><span>{{ poste.nom }}</span></v-col>
                <v-col cols="6" style="padding:0px 60px 0px 0px;text-align:right;" class="gros-text"  >{{ poste.affichage }}<span v-if="poste.affichage">CO<sub>2</sub>eq</span></v-col>
                <div style="display:flex;justify-content:right">
                  <v-img v-if="open"
                         style="width: 46px; position: absolute; margin-top: -23px; right:5px"
                         src="/icons/png/chevron.png"
                         class="rotate_chevron_up"
                  ></v-img>
                  <v-img v-else
                         style="width: 46px; position: absolute; margin-top: -23px; right:5px"
                         src="/icons/png/chevron.png"
                         class="rotate_chevron_down"
                  ></v-img>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>




                <!--
                <v-expansion-panel-content >
                  <div v-for="ressource in poste.ressources" :key="ressource.index">
                    <div style="padding:5px" >

                      <v-row style="align-items:center">
                        <v-col cols="4" style="padding-left:20px">
                          <span class="text-normal">{{ ressource.nom }}</span>
                        </v-col>
                        <v-col cols="4">

                          <input
                                  v-model="ressource.saisie"
                                  class="saisie"
                                  :id="ressource.id"
                                  style="width:130px;text-align:right;border-radius: 5px;padding: 8px;height:30px"
                          @change="caculCARB(ressource.index)"
                          />&nbsp;{{ ressource.unite }}
                        </v-col>
                        <v-col cols="4" class="text-moyen" style="text-align:right;padding-right:20px">
                          <span>{{ ressource.affichage }}</span>
                          <span v-if="ressource.affichage">CO<sub>2</sub>eq</span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expansion-panel-content>
                -->


              <div v-for="ressource in poste.ressources" :key="ressource.index" >

                <v-expansion-panels v-if="ressource.sp == 1" v-expansion-panels
                                    :disabled="disabled"
                                    multiple
                >

                <v-expansion-panel :id="ressource.id" class="panel_sousposte" >

                <v-expansion-panel-header hide-actions >

                  <template v-slot:default="{ open }">
                    <v-col cols="6" style="padding:0px 0px 0px 0px; margin-left: -10px" class="text-normal"><span>{{ ressource.nom }}</span></v-col>
                    <v-col cols="6" style="padding:0px 60px 0px 0px;text-align:right;" class="gros-text"  >{{ ressource.affichage }}<span v-if="ressource.affichage">CO<sub>2</sub>eq</span></v-col>
                    <div style="display:flex;justify-content:right">
                      <v-img v-if="open"
                             style="width: 46px; position: absolute; margin-top: -23px; right:5px"
                             src="/icons/svg/chevron.svg"
                             class="rotate_chevron_up"
                      ></v-img>
                      <v-img v-else
                             style="width: 46px; position: absolute; margin-top: -23px; right:5px"
                             src="/icons/svg/chevron.svg"
                             class="rotate_chevron_down"
                      ></v-img>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content >
                  <div v-for="s_ressource in ressource.ressources" :key="s_ressource.index">
                    <div style="padding:5px" >

                      <v-row style="align-items:center">
                        <v-col cols="4" style="padding-left:20px">
                          <span class="text-normal">{{ s_ressource.nom }}</span>
                        </v-col>
                        <v-col cols="4">

                          <input
                                  v-model="s_ressource.saisie"
                                  class="saisie"
                                  :id="s_ressource.id"
                                  style="width:130px;text-align:right;border-radius: 5px;padding: 8px;height:30px"
                          @change="caculCARB(s_ressource.index)"
                          />&nbsp;{{ s_ressource.unite }}
                        </v-col>
                        <v-col cols="4" class="text-moyen" style="text-align:right;padding-right:20px">
                          <span>{{ s_ressource.affichage }}</span>
                          <span v-if="s_ressource.affichage">CO<sub>2</sub>eq</span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expansion-panel-content>

                </v-expansion-panel>
                </v-expansion-panels>



                <div v-else class="panel_saisie" style="padding:5px">
                  <v-row style="align-items:center">
                        <v-col cols="4" style="padding-left:20px">
                          <span class="text-normal">{{ ressource.nom }}</span>
                        </v-col>
                        <v-col cols="4">

                        <input
                          v-model="ressource.saisie"
                          class="saisie"
                          :id="ressource.id"
                          style="width:130px;text-align:right;border-radius: 5px;padding: 8px;height:30px"
                          @change="caculCARB(ressource.index)"
                        />&nbsp;{{ ressource.unite }}
                        </v-col>
                        <v-col cols="4" class="text-moyen" style="text-align:right;padding-right:20px">
                          <span>{{ ressource.affichage }}</span>
                          <span v-if="ressource.affichage">CO<sub>2</sub>eq</span>
                        </v-col>
                  </v-row>
                </div>

              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>

        <v-row>
            <v-col>
              <div style="padding-top:20px">
              Facteurs d'émissions mis à jour en {{ date_fe_maj }}
              </div>
            </v-col>
<!--            
            <v-col v-if="reduct" style="text-align:center">
                <v-btn
                        class="bouton"
                        text
                @click="$router.push({ name: 'Reduction' })"
                >
                Mes actions de réduction
                </v-btn>
            </v-col>

            
            <v-col style="text-align:right">
                <v-btn
                class="bouton"
                text
                @click="dialog_entreprise = true;"
                >
                Exporter
                </v-btn>
            </v-col>
-->

            <v-col style="text-align:right">
                <v-btn
                class="bouton"
                text
                @click="GenerateReport()"
                >
                Exporter
                </v-btn>
            </v-col>


        </v-row>

      </v-card>
    </div>
  </v-row>

  <div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      filename="Export"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination="true"
      :paginate-elements-by-height="800"

    @progress="onProgress($event)"
    @startPagination="startPagination()"
    @hasPaginated="hasPaginated()"
    @hasDownloaded="hasDownloaded($event)"
    ref="html2Pdf"
    >
    <section slot="pdf-content" id="export_pdf" >

    <v-row class="top-header-flex" style="justify-content: center;margin-top:10px">

<!--
      <div style="width:105%;display:flex;height:80px">
        <img
                style="margin-top:5px;margin-left:15px"
                height="50"
                width="133"
                src="/icons/svg/fntp-logo.svg"
        >
        </img>
          <span style="margin-top:-20px;
          margin-left:40px;
          font-family: Dosis, sans-serif;
          font-size: 30px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          align-self: center;
          padding-left: 80px;
          z-index:1;
      ">Bilan des émissions</span>
        <div style="position: absolute; right: 0px;" >
        <img
                style="margin-right:5px"
                height="60"
                src="/icons/png/FNTP.png"
        >
        </img>
        </div>
      </div>
-->
    </v-row>

    <div style="margin:0px 12px 0px 5px">

      <entete v-bind:entreprise="entreprise" v-bind:date_rapport="date_rapport"></entete>

        <v-row>

          <v-col cols="2.4" >
            <div v-if="grandTotal.affichage" class="div_total" style="text-align:center;padding:5px;margin-top:-5px;font-weight:bold">
              <span class="text-normal" style="font-weight:bold" >{{grandTotal.label}} {{ grandTotal.affichage.substring(0, grandTotal.affichage.lastIndexOf(' ')) }} {{ grandTotal.affichage.substring(grandTotal.affichage.lastIndexOf(' '),grandTotal.affichage.length) }}CO<sub>2</sub>eq</sup></span>
            </div>
            <div v-else class="div_total" style="text-align:center;padding:5px;margin-top:-5px;font-weight:bold">
              <span class="text-normal" style="font-weight:bold" >{{grandTotal.label}} 0 kgCO<sub>2</sub>eq</sup></span>
            </div>
          </v-col>

<!--
          <v-col cols="2.4" >
            <div v-if="grandTotal.affichage" class="div_total" style="text-align:center;padding:10px">
            <span class="gros-text-PDF" style="width:100%;">{{ grandTotal.affichage.substring(0, grandTotal.affichage.lastIndexOf(' ')) }}</span>
            <div class="text-normal" >{{ grandTotal.affichage.substring(grandTotal.affichage.lastIndexOf(' '),grandTotal.affichage.length) }}CO<sub>2</sub>eq</sup></div>
            </div>
            <div v-else class="div_total" style="text-align:center;padding:10px">
            <span class="gros-text-PDF" style="width:100%">0</span>
            <div class="text-normal" >kgCO<sub>2</sub>eq</div>
            </div>
            <div class="div_total" style="text-align:center;padding:5px;margin-top:-5px">
            <span>{{grandTotal.label}}</span>
            </div>
          </v-col>

          <v-col cols="2.4"  v-for="total in totaux" :key="total.index">
            <div v-if="total.affichage" class="div_poste" style="text-align:center;padding:10px">
            <span class="gros-text-PDF" style="width:100%;">{{ total.affichage.substring(0, total.affichage.lastIndexOf(' ')) }}</span>
            <div class="text-normal" >{{ total.affichage.substring(total.affichage.lastIndexOf(' '),total.affichage.length) }}CO<sub>2</sub>eq</sup></div>
            </div>
            <div v-else class="div_poste" style="text-align:center;padding:10px">
            <span class="gros-text-PDF" style="width:100%">0</span>
            <div class="text-normal" >kgCO<sub>2</sub>eq</div>
            </div>
            <div class="div_poste" style="text-align:center;padding:5px;margin-top:-5px">
            <span>{{total.label}}</span>
            </div>
          </v-col>

-->
    
        </v-row>

        <div class="panel_PDF" style="width:100%" v-if="mes_scopes[0]">
          <div style="padding:10px" >
            <div style="color:#000000 !important"><u>L'emprunte de vôtre activité corespond à un equivalant de </u> <b>{{ AfficheMeilleurUniteCARB(mes_scopes[0].valeur + mes_scopes[1].valeur + mes_scopes[2].valeur) }}CO<sub>2</sub>eq</b></div>
              <br/>
              La repartition par scope est la suivante :
              <ul style="padding:10px" >
                  <li> SCOPE 1: <b>{{ AfficheMeilleurUniteCARB(mes_scopes[0].valeur) }}CO<sub>2</sub>eq</b> (émissions directes liées à votre activité)</li>
                  <li> SCOPE 2: <b>{{ AfficheMeilleurUniteCARB(mes_scopes[1].valeur) }}CO<sub>2</sub>eq</b> (émissions associées à la consommation d’électricité ou de vapeur)</li>
                  <li> SCOPE 3: <b>{{ AfficheMeilleurUniteCARB(mes_scopes[2].valeur) }}CO<sub>2</sub>eq</b> (émissions indirectes de votre activité)</li>
              </ul>
          </div>
        </div>

        <br/>

        <!-- Saut de page PDF -->

        <div class="html2pdf__page-break"/>
        <div>&nbsp;</div>
        <entete v-bind:entreprise="entreprise" v-bind:date_rapport="date_rapport" ></entete>
        <!-- 28% d'occupation page PDF jusqu'ici-->


        <div v-for="scope_pdf in mes_scopes" style="margin:5px;width:100%" >

          <!--  Scope 15% d'occupation-->
          <span class="scope_PDF" style="padding-top:20px">{{ scope_pdf.nom }}</span>
          <div v-if="scope_pdf.postes.length < 1" class="text-normal-PDF" style="padding:5px" >Vous n'avez pas renseigné d'émissions sur le {{ scope_pdf.nom }}
          </div>
          <div v-for="poste_pdf in scope_pdf.postes" style="margin:5px;width:100%">

            <div v-if="poste_pdf.saut_de_ligne">
              <div class="html2pdf__page-break"/>
              <div>&nbsp;</div>
              <entete v-bind:entreprise="entreprise" v-bind:date_rapport="date_rapport"></entete>
            </div>

            <div class="panel_PDF">

              <!-- ressources 5% d'occupation -->
              <div class="text-moyen-PDF" style="padding:5px">
                <v-row style="align-items:center">
                  <v-col style="padding-left:20px" ><span >{{ poste_pdf.nom }}</span></v-col>
                  <v-col style="text-align:right;padding-right:20px" >{{ poste_pdf.affichage }}<span v-if="poste_pdf.affichage != 'Suite'" >CO<sub>2</sub>eq</span></v-col>
                </v-row>
                <div class="trait_PDF"></div>
              </div>

              <div v-for="ressource_pdf in poste_pdf.ressources" style="">


                <section v-if="ressource_pdf.sp == 0" class="pdf-item" >
                <div class="text-normal-PDF" style="padding: 5px;">

                  <v-row style="align-items:center">
                    <v-col cols="4" style="padding-left:20px">
                      <span >{{ ressource_pdf.nom }}</span>
                    </v-col>
                    <v-col cols="4">
                      <span>{{ ressource_pdf.saisie }} {{ ressource_pdf.unite }}</span>
                    </v-col>
                    <v-col cols="4" style="text-align:right;padding-right:20px">
                      <span>{{ ressource_pdf.affichage }}</span>
                      <span>CO<sub>2</sub>eq</span>
                    </v-col>
                  </v-row>
                </div>
                </section>


                <div v-if="ressource_pdf.sp == 1" style="padding-left:5px">
                  <v-row style="align-items:center">
                    <v-col style="padding-left:20px;"><span>{{ ressource_pdf.nom }}</span></v-col>
                  </v-row>
                  <div v-for="ressource_sp_pdf in ressource_pdf.ressources" style="">
                    <section class="pdf-item">

                    <div class="text-normal-PDF" style="padding: 5px;">

                      <v-row style="align-items:center">
                        <v-col cols="4" style="padding-left:20px">
                          <span> • {{ ressource_sp_pdf.nom }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span>{{ ressource_sp_pdf.saisie }} {{ ressource_sp_pdf.unite }}</span>
                        </v-col>
                        <v-col cols="4" style="text-align:right;padding-right:20px">
                          <span>{{ ressource_sp_pdf.affichage }}</span>
                          <span>CO<sub>2</sub>eq</span>
                        </v-col>
                      </v-row>
                    </div>
                    </section>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>

    </div>

    </section>
    </vue-html2pdf>

  </div>




  </v-container>
</template>
<script>


  import calculator_mixin from "./mixins/calculator_mixin";
  import Entete from "./Entete.vue"
  import axios from "axios";
  import VueHtml2pdf from 'vue-html2pdf'

  const optionsChartOOH = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateRotate: false,
    },
  }



  export default {
    name: 'CarbonCalculator',
    components: {
      'entete' : Entete
    },
    mixins: [calculator_mixin],
    data() {
      return {
        date_rapport: '',
        reduct: true,
        date_fe:'',
        date_fe_maj:'',
        entreprise:{ nom:'',
          siren:'',
          adresse_rue:'',
          adresse_CP:'',
          adresse_ville:''
        },
        dialog_entreprise: false,
        dialog_load: false,
        update:false,
        pdf_completion_page:0,
        grandTotal:{
            index:0,
            label:'Total',
            valeur:0,
            unite:'kgCO2',
        },
        totaux:[
          {
            index:0,
            label:'Combustibles',
            valeur:0,
            unite:'kgCO2',
          },
          {
            index:1,
            label:'Matériaux',
            valeur:0,
            unite:'kgCO2',
          },
          {
            index:2,
            label:'Transports',
            valeur:0,
            unite:'kgCO2',
          },
          {
            index:3,
            label:'Autres',
            valeur:0,
            unite:'kgCO2',
          },
        ],
        panels: [],
        mes_postes: [
          {
            nom:'Electricité',
            valeur:'',
          }
        ],
        mes_ressources: [
          {
            index:0,
            id:'Combustion-Electricité',
            saisie:'',
            scope:1,
            poste:'Electricité',
            valeur:'',
            unite:'kWh',
            nom:'Combustion',
            conversion:0.0395,
          }
        ],
        mes_scopes:[],
      }
    },
    computed: {},

    mounted() {

        window.scrollTo(0, 0);

        if( window.location.hostname.includes("fntp.fr") )
        {
          this.reduct = false;
        }

        this.mes_ressources=[];
        this.mes_postes=[];


        this.get_date_fe().then((res) => {
          this.date_fe = res;
          let date_tab = this.date_fe.split('-')
          let mois = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
          this.date_fe_maj = mois[(parseInt(date_tab[1])-1)]+' '+date_tab[2];

          this.get_conf(this.date_fe).then((res) => {

            let poste_releves = [];
            let sousposte_releves = [];

            let idx = 0;
            res.forEach( elem => {

              let sp_obj = {};
              sp_obj.sp = 1 ; // est un souspost !
              sp_obj.nom = elem['Sous-poste'];
              sp_obj.ressources = [];
              sp_obj.id = elem['id sous-poste'];
              sp_obj.valeur = 0
              sp_obj.affichage = ''
              sp_obj.index = -1;

              let src_obj = {};
              src_obj.index = idx++;
              src_obj.id = elem['id source'];
              src_obj.sp = 0 ; // est une ressource !
              src_obj.nom = elem["Source d'émission"];

              src_obj.scope = elem["Totaux d'émission - Compteur du calculateur"];
              src_obj.poste = elem["poste d'émission"];
              src_obj.sousposte = elem['Sous-poste'];
              src_obj.id_sp = elem['id sous-poste'];

              src_obj.conversion_scope = [ parseFloat( elem['Emissions directes'].replace(',','.') ),
                                           parseFloat( elem["Emissions indirectes liées à l'énergie"].replace(',','.') ),
                                           parseFloat( elem['Emissions scope 3 amont'].replace(',','.') ) ];

              if ( !src_obj.conversion_scope[0] && src_obj.conversion_scope[0] != 0.0 ) src_obj.conversion_scope[0] = '' ;
              if ( !src_obj.conversion_scope[1] && src_obj.conversion_scope[1] != 0.0) src_obj.conversion_scope[1] = '' ;
              if ( !src_obj.conversion_scope[2] && src_obj.conversion_scope[2] != 0.0 ) src_obj.conversion_scope[2] = '' ;

              src_obj.valeur = '0';
              src_obj.saisie = '';
              src_obj.affichage = '';
              src_obj.unite = elem['Unité'];

              src_obj.conversion = parseFloat( elem['Valeur kg eq CO2)'].replace(',','.') );


              let match_poste = false;
              this.mes_postes.forEach( r_poste => {

                    if (r_poste.nom == src_obj.poste)
                    {
                        match_poste = true;
                        if( elem['Sous-poste'] != '' )
                        {
                          let match_sp = false;
                          r_poste.ressources.forEach( p_ress => {
                            if (p_ress.sp == 1 && p_ress.nom == elem['Sous-poste'])
                            {
                              match_sp = true;
                              p_ress.ressources.push(src_obj);
                            }
                          });
                          if(!match_sp)
                          {
                            sp_obj.ressources.push(src_obj);
                            r_poste.ressources.push(sp_obj);
                          }
                        }
                        else
                        {
                          r_poste.ressources.push(src_obj);
                        }
                    }
              });
              if (!match_poste)
              {
                let p_post = { nom:src_obj.poste, valeur:0, affichage:'', ressources:[] }
                if( elem['Sous-poste'] != '' )
                {
                  let match_sp = false;
                  p_post.ressources.forEach( p_ress => {
                    if (p_ress.sp == 1 && p_ress.nom == elem['Sous-poste'])
                    {
                      match_sp = true;
                      p_post.ressources.push(src_obj);
                    }
                  });
                  if(!match_sp)
                  {
                    sp_obj.ressources.push(src_obj);
                    p_post.ressources.push(sp_obj);
                  }
                }
                else
                {
                  p_post.ressources.push(src_obj);
                }
                this.mes_postes.push(p_post)
              }

            });


            let version = window.location.search // look for  '?version'
            if (version == '?version')
            {
              alert("Version du fichier de configuration : " + this.date_fe);
            }

            this.LoadValeurCookie();

          });

        });
    },
    methods: {

     setCookie(name,value,days) {
          let expires = "";
          if (!days)
          {
            days = 700;
          }

          let date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();

          document.cookie = name + "=" + (value || "")  + expires + "; path=/";
      },

     getCookie(name) {
          let nameEQ = name + "=";
          let ca = document.cookie.split(';');
          for(let i=0;i < ca.length;i++) {

              console.log( ca[i] )
              let c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1,c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);

          }
          return null;
      },


      set_cookie_entreprise()
      {
        let str_cook = '{"nom":"' + this.entreprise.nom + '"' ;
        str_cook = str_cook + ', "siren":"' + this.entreprise.siren + '"'  ;
        str_cook = str_cook + ', "adresse_rue":"' + this.entreprise.adresse_rue + '"'  ;
        str_cook = str_cook + ', "adresse_CP":"' + this.entreprise.adresse_CP + '"'  ;
        str_cook = str_cook + ', "adresse_ville":"' + this.entreprise.adresse_ville + '"}' ;

        this.setCookie('entreprise',str_cook);
      },


      LoadValeurCookie()
      {

        let cook_str = this.getCookie('entreprise');
        if ( cook_str != null )
        {
          this.entreprise = JSON.parse(cook_str);
        }

        cook_str = this.getCookie('mes_postes');
        let cook_postes = JSON.parse(cook_str);

        cook_postes.forEach( poste => {

          cook_str = this.getCookie(poste);
          let cook_poste = JSON.parse(cook_str);


          cook_poste.forEach( ress_cook => {

            let corespondance = 0;

            this.mes_postes.forEach( pst => {

              if (pst.nom == poste)
              {
                pst.ressources.forEach( ress => {
                  if( ress.sp == 0 )
                  {
                    if( ress.id == ress_cook.id  && ress_cook.saisie != "")
                    {
                      corespondance = 1;
                      ress.saisie = ress_cook.saisie;
                      this.caculCARB(ress.index,false);
                    }
                  }
                  else
                  {
                    ress.ressources.forEach( s_ress => {
                    if( s_ress.id == ress_cook.id  && ress_cook.saisie != "")
                    {
                      corespondance = 1;
                      s_ress.saisie = ress_cook.saisie;
                      this.caculCARB(s_ress.index,false);
                    }
                    });
                  }
                });
              }
            });

            if( corespondance == 0 && ress_cook.saisie != "" )
            {
              this.dialog_load = true;
            }

          });

        });
      },

      EffaceSaisie()
      {
        this.mes_postes.forEach( elem =>
        {
            elem.valeur = 0; elem.affichage = '';
            elem.ressources.forEach( relem => {

             relem.valeur = 0; relem.affichage = ''; relem.saisie = ''
             if (relem.sp != 0)
             {
                relem.ressources.forEach( srelem => {srelem.valeur = 0; srelem.affichage = ''; srelem.saisie = ''});
             }

            });
        });

        this.totaux.forEach( elem => { elem.valeur = 0;  elem.affichage = this.AfficheMeilleurUniteCARB(0) });
        this.grandTotal.valeur = 0;  this.grandTotal.affichage = this.AfficheMeilleurUniteCARB(0) ;
      },


      GR_flaot_saisie(saisie)
      {
        let float_elem_saisie = parseFloat( saisie.replaceAll(' ','').replaceAll(',','.') );
        if(!isNaN(float_elem_saisie))
          return float_elem_saisie;
        return 0.;
      },

      GR_ajoute_sur_poste_original(scopid,nom_post,valeur)
      {
          let nomatch = 1;
          this.mes_scopes[scopid].postes.forEach( pp => {
            if ( nomatch && pp.nom == nom_post)
            {
              nomatch = 0;
              pp.valeur += valeur
              pp.affichage = this.AfficheMeilleurUniteCARB(pp.valeur);
            }
          })
      },

      GR_test_depassement_page(scopid,saut_ligne,nom_post)
      {
        if (saut_ligne > 130 )
        {

          let post_obj = {};
          post_obj.nom = nom_post;
          post_obj.valeur = 0;
          post_obj.affichage = 'Suite';
          post_obj.ressources = [];
          post_obj.suite = 1;
          post_obj.saut_de_ligne = true;

          return post_obj;
        }
        return '';
      },

      GenerateReport()
      {
            let mois = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
            var ndate = new Date()
            this.date_rapport = ndate.getDate() + ' ' + mois[ndate.getMonth()] + ' ' + ndate.getFullYear()

            let src_obj_sautpdf = {'nom':'sautpdf', 'poste':'', 'souposte':''};

            // % occupation taille de page du début du fichier ( avant les scope )
            let saut_ligne = 60
            saut_ligne = 0 // force Saut de ligne page de garde

            this.mes_scopes = [
              {'nom':'Scope 1', 'valeur':0, 'postes':[]},
              {'nom':'Scope 2', 'valeur':0, 'postes':[]},
              {'nom':'Scope 3', 'valeur':0, 'postes':[]}
            ];


            // il serait plus judiceux de parcourir les ressources,
            // puis pour chaque ressource parcourir les scope, mais cela rendrait le comptage d'occupation de la page
            // imposible à réaliser, nous sommes donc obligés de boucler dans l'ordre d'affichage des elements.
            for (let scopid in [0,1,2])
            {
              // % occupation taille du mot Scope + eventuelle ligne scope vide
              saut_ligne += 20;

              this.mes_postes.forEach( pp => {

                let post_obj = {};
                post_obj.nom = pp.nom;
                post_obj.valeur = 0;
                post_obj.affichage = '';
                post_obj.ressources = [];
                post_obj.suite = 0;
                post_obj.saut_de_ligne = 0;

                pp.ressources.forEach( rr => {

                  // ressource de poste
                  if (rr.sp == 0)
                  {
                    // saisie valide et dans le scope
                    if(rr.conversion_scope[scopid] != '' && this.GR_flaot_saisie(rr.saisie) > 0.0 )
                    {

                      let src_obj = {};
                      src_obj.sp = 0 ; // n'est pas un souspost !
                      src_obj.nom = rr.nom;
                      src_obj.scope = rr.scope;
                      src_obj.saisie = rr.saisie;
                      src_obj.unite = rr.unite;
                      src_obj.valeur = this.GR_flaot_saisie(rr.saisie) * rr.conversion_scope[scopid];
                      src_obj.affichage = this.AfficheMeilleurUniteCARB(src_obj.valeur);

                      this.mes_scopes[scopid].valeur += src_obj.valeur;
                      this.mes_scopes[scopid].affichage = this.AfficheMeilleurUniteCARB(src_obj.valeur);

                      if( post_obj.suite == 0)
                      {
                        post_obj.valeur +=  src_obj.valeur;
                        post_obj.affichage = this.AfficheMeilleurUniteCARB(post_obj.valeur);
                      }
                      else
                      {
                        this.GR_ajoute_sur_poste_original(scopid,post_obj.nom,src_obj.valeur)
                      }

                      saut_ligne += 4; // % occupation taille de page d'une ligne
                      post_obj.ressources.push(src_obj);

                      let suite_post = this.GR_test_depassement_page(scopid,saut_ligne,post_obj.nom)
                      if ( suite_post != '')
                      {
                        saut_ligne = 28; //entête
                        this.mes_scopes[scopid].postes.push(post_obj);
                        post_obj = suite_post;
                      }

                    }

                  }
                  // ressource de sous-poste
                  else
                  {
                    let sp_obj = {};
                    sp_obj.sp = 1 ; // est un souspost !
                    sp_obj.nom = rr.nom;
                    sp_obj.ressources = [];

                    let sousposte_deja_compte = 0;
                    rr.ressources.forEach( sr => {

                      // saisie valide et dans le scope
                      if(sr.conversion_scope[scopid] != '' && this.GR_flaot_saisie(sr.saisie) )
                      {
                        let ssrc_obj = {};
                        ssrc_obj.sp = 0 ; // n'est pas un souspost !
                        ssrc_obj.nom = sr.nom;
                        ssrc_obj.scope = sr.scope;
                        ssrc_obj.saisie = sr.saisie;
                        ssrc_obj.unite = sr.unite;
                        ssrc_obj.valeur = this.GR_flaot_saisie(sr.saisie) * sr.conversion_scope[scopid];
                        ssrc_obj.affichage = this.AfficheMeilleurUniteCARB(ssrc_obj.valeur);

                        this.mes_scopes[scopid].valeur += ssrc_obj.valeur;
                        this.mes_scopes[scopid].affichage = this.AfficheMeilleurUniteCARB(ssrc_obj.valeur);
                        if( post_obj.suite == 0)
                        {
                          post_obj.valeur +=  ssrc_obj.valeur;
                          post_obj.affichage = this.AfficheMeilleurUniteCARB(post_obj.valeur);
                        }
                        else
                        {
                          this.GR_ajoute_sur_poste_original(scopid,post_obj.nom,ssrc_obj.valeur)
                        }

                        if( !sousposte_deja_compte )
                        {
                          sousposte_deja_compte = 1;
                          saut_ligne += 6; // % occupation taille de page d'un souposte
                        }

                        saut_ligne += 4; // % occupation taille de page d'une ligne
                        sp_obj.ressources.push(ssrc_obj);

                        let suite_post = this.GR_test_depassement_page(scopid,saut_ligne,post_obj.nom)
                        if ( suite_post != '')
                        {
                          saut_ligne = 28; //entête

                          if( sp_obj.ressources.length > 0 )
                          {
                            post_obj.ressources.push(sp_obj);
                          }
                          this.mes_scopes[scopid].postes.push(post_obj);

                          post_obj = suite_post;

                          sp_obj = {};
                          sp_obj.sp = 1 ; // est un souspost !
                          sp_obj.nom = rr.nom;
                          sp_obj.ressources = [];

                        }
                      }

                    });

                    if( sp_obj.ressources.length > 0 )
                    {
                      saut_ligne += 6; //souposte
                      post_obj.ressources.push(sp_obj);
                    }
                  }

                });

                if(post_obj.ressources.length > 0)
                {
                  saut_ligne += 6; //poste
                  this.mes_scopes[scopid].postes.push(post_obj);
                }

              });

            }
            this.$refs.html2Pdf.generatePdf();
      },


      AfficheEnTonnes(valeur)
      {
        if(valeur > 0)
        {
            let val = valeur / 1000.0;
            let unite = 't';
            return ( this.formatAsNumber( ' '+Math.round(val * 100) / 100) ) + ' ' + unite ;
        }
        return '0 t';
      },

      AfficheMeilleurUniteCARB(valeur)
      {
        if(valeur > 0)
        {
          let unite = 'kg';
          let val = valeur;

          if (valeur > 1000000000.0)
          {
            val = valeur / 1000000000.0;
            unite = 'Mt'
          }
          else if (valeur > 1000000.0)
          {
            val = valeur / 1000000.0;
            unite = 'kt'
          }
          else if (valeur > 1000.0)
          {
            val = valeur / 1000.0;
            unite = 't'
          }

          // return ( this.formatAsNumber( ' '+Math.round(val * 100) / 100) ) + ' ' + unite ;
          return ( this.formatAsNumber( ' '+Math.round(val)))+ ' ' + unite ;
        }
        return '0 kg';
      },

      formatAsNumber(str)
      {
        // CODE NON COMPATIBLE AVEC SAFARI
        //return str.replace(/\./g, ',')  // on remplace les points par des virgules
        // .replace(/(?<=,.*),/g, '')    // on ne garde que la première virgule
        //.replace(/[^0-9,]/g,'')       // on ne garde que l'eventuelle virgule et les chiffres
        //.replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g,' ')  // on insert un espace tout les groupes de 3 chiffres avant la virgule

        let temp_val;
        let retour;
        temp_val = str.replace(/\./g, ',') // on remplace les points par des virgules
        .replace(/[^0-9,]/g,'') // on ne garde que les eventuelles virgules et les chiffres
        .split(',') // on decoupe par virgule

        // on travail sur la partie avant la virgule
        retour = temp_val[0];
        // on insert un espace tout les groupes de 3 chiffres
        let temp_ret = retour
        let len = temp_ret.length;
        if ( len > 3 )
        {
          retour = '';
          let grp = 3;
          for ( let i=len-1; i>-1; i--)
          {
            if ( grp == 0 )
            {
              retour = ' ' + retour;
              grp = 3;
            }
            retour = temp_ret[i] + retour;
            grp = grp - 1;
          }
        }

        // on rattache l'eventuelle partie après la virgule
        len = temp_val.length;
        if ( len > 1 )
        {
          retour = retour + ',';
          for ( let i=1; i<len; i++)
          {
            retour = retour + temp_val[i];
          }
        }
        return retour;
      },

      indiceDuTotal(nomTotal)
      {
          let len = this.totaux.length;
          for( let i=0; i<len; i++)
          {
            if ( this.totaux[i].label == nomTotal )
              return i;
          }
         return 3; // Autres par défault
      },


      caculCARB(id, setCookies=true)
      {
        // on cherche la resource
        let p_post = ''
        let r_ress = ''
        let sp_ress = ''

        this.mes_postes.forEach( pp => {

          pp.ressources.forEach( rr => {

            if (rr.sp == 0)
            {
              if ( rr.index == id )
              {
                p_post = pp;
                r_ress = rr;
              }
            }
            else
            {
              rr.ressources.forEach( sr => {
                if ( sr.index == id )
                {
                  p_post = pp;
                  sp_ress = rr;
                  r_ress = sr;
                }
              })
            }
          });
        });

        if(r_ress == '')
        {
          return;
        }

        // on reformat le champs
        r_ress.saisie = this.formatAsNumber(r_ress.saisie);
        // on extrait la valeur float/int du champs
        let saisie = parseFloat(r_ress.saisie.replaceAll(' ','').replaceAll(',','.'))

        if (saisie > 0)
        {
          let val = saisie * r_ress.conversion;

          // Gestion scope3 pour valeur avec scope 3 et scope 1 ou 2
          if ( (''+r_ress.conversion_scope[2]) != '' && ( (''+r_ress.conversion_scope[0]) != '' || (''+r_ress.conversion_scope[1]) != '' ) )
          {
            // on ajoute le scope 3 qu'on retranchera au total du poste par la suite, et qu'on positionnera dans 'Autres'
            val += saisie * r_ress.conversion_scope[2];
          }

          r_ress.valeur = val
          r_ress.affichage = this.AfficheMeilleurUniteCARB( val );
        }
        else
        {
          r_ress.valeur = 0;
          r_ress.affichage = '';
        }


        let total_poste = 0;
        let total_sousposte = 0;

        this.grandTotal.valeur = 0;

        this.totaux.forEach( tot => { tot.valeur = 0 ;} );


        this.mes_postes.forEach( pp => {

          pp.ressources.forEach( rr => {

            if (rr.sp == 0)
            {
              // evite d'attitioner des chiffres et des valeurs vide
              if( rr.saisie != '' )
              {
                if(pp.nom == p_post.nom)
                {
                  total_poste += rr.valeur;
                }
                this.grandTotal.valeur  += rr.valeur;
                let indexTot = this.indiceDuTotal(rr.scope);
                if ( indexTot > -1 )
                {
                  // ajout valeur dans soustotal associé
                  this.totaux[indexTot].valeur += rr.valeur;

                  // Gestion scope3 pour valeur avec scope 3 et scope 1 ou 2
                  if ( (''+rr.conversion_scope[2]) != '' && ( (''+rr.conversion_scope[0]) != '' || (''+rr.conversion_scope[1]) != '' ) )
                  {
                      // scope3 dans soustotal 'Autres' pour valeur avec scope 3 et scope 1 ou 2
                      let saisie_s = parseFloat(rr.saisie.replaceAll(' ','').replaceAll(',','.'))
                      let val_s = saisie_s * rr.conversion_scope[2];
                      this.totaux[3].valeur += val_s;

                      // scope3 en moins dans soustotal associé à la ressource pour valeur avec scope 3 et scope 1 ou 2
                      // soustraction du scope 3 dans soustotal associé
                      this.totaux[indexTot].valeur -= val_s;
                  }
                }
              }
            }
            else
            {
              rr.ressources.forEach( sr => {

                // evite d'attitioner des chiffres et des valeurs vide
                if( sr.saisie != '' )
                {
                  if(pp.nom == p_post.nom)
                  {
                    total_poste += sr.valeur;
                  }
                  if( sp_ress != '' && rr.id == sp_ress.id)
                  {
                    total_sousposte += sr.valeur;
                  }

                  this.grandTotal.valeur  += sr.valeur;
                  let indexTot = this.indiceDuTotal(sr.scope);
                  if ( indexTot > -1 )
                  {
                    // ajout valeur dans soustotal associé
                    this.totaux[indexTot].valeur += sr.valeur;

                    // Gestion scope3 pour valeur avec scope 3 et scope 1 ou 2
                    if ( (''+sr.conversion_scope[2]) != '' && ( (''+sr.conversion_scope[0]) != '' || (''+sr.conversion_scope[1]) != '' ) )
                    {
                        // scope3 dans soustotal 'Autres' pour valeur avec scope 3 et scope 1 ou 2
                        let saisie_s = parseFloat(sr.saisie.replaceAll(' ','').replaceAll(',','.'))
                        let val_s = saisie_s * sr.conversion_scope[2];
                        this.totaux[3].valeur += val_s;

                        // scope3 en moins dans soustotal associé à la ressource pour valeur avec scope 3 et scope 1 ou 2
                        // soustraction du scope 3 dans soustotal associé
                        this.totaux[indexTot].valeur -= val_s;
                    }
                  }
                }

              })
            }
          });
        })

        p_post.valeur = total_poste;
        if (total_poste > 0)
          p_post.affichage = this.AfficheMeilleurUniteCARB(total_poste);
        else
          p_post.affichage = '';

        if(sp_ress != '')
        {
          sp_ress.valeur = total_sousposte;
          if (total_sousposte > 0)
            sp_ress.affichage = this.AfficheMeilleurUniteCARB(total_sousposte);
          else
            sp_ress.affichage = '';
        }

        this.grandTotal.affichage = this.AfficheMeilleurUniteCARB(this.grandTotal.valeur);
        this.totaux.forEach( tot => { tot.affichage = this.AfficheMeilleurUniteCARB(tot.valeur); } );

        this.$parent.emissions = this.totaux;

        if(setCookies)
        {
          let mes_cookie = "[";
          let first_post = 1;
          this.mes_postes.forEach( pos => {
            if (first_post == 0)
            {
              mes_cookie = mes_cookie + ',' ;
            }
            first_post = 0;
            mes_cookie = mes_cookie + '"' + pos.nom + '"';

            let mon_cookie = "[";
            let first_ress = 1;
            pos.ressources.forEach( ress => {
              if(ress.sp == 0)
              {
                if (first_ress == 0)
                {
                  mon_cookie = mon_cookie + ',' ;
                }
                first_ress = 0;
                mon_cookie = mon_cookie + '{"id": "'+ress.id+'", "saisie": "'+ress.saisie+'"}';
              }
              else
              {
                ress.ressources.forEach( sress => {
                  if (first_ress == 0)
                  {
                    mon_cookie = mon_cookie + ',' ;
                  }
                  first_ress = 0;
                  mon_cookie = mon_cookie + '{"id": "'+sress.id+'", "saisie": "'+sress.saisie+'"}';
                });
              }
            });
            mon_cookie = mon_cookie + "]";
            this.setCookie(pos.nom, mon_cookie);

          });
          mes_cookie = mes_cookie + "]";
          this.setCookie("mes_postes", mes_cookie);
        }

      },
    },
  }
</script>
<style lang="scss">

.text_bleu
{
  color: #46006f !important;
}

.gros-text-PDF
{
  font-size:1.5rem
}
.text-moyen-PDF
{
  font-size:1.2rem
}
.text-normal-PDF
{
  font-size:1rem
}


.gros-text
{
  font-size:1.5rem
}
@media screen and (max-width: 900px) {
  .gros-text
  {
    font-size:1.2rem
  }
}
@media screen and (max-width: 600px) {
  .gros-text
  {
    font-size:1rem
  }
}


.text-moyen
{
  font-size:1.4rem
}
@media screen and (max-width: 900px) {
  .text-moyen
  {
    font-size:1.1rem
  }
}
@media screen and (max-width: 600px) {
  .text-moyen
  {
    font-size:0.90rem
  }
}


.text-normal
{
  font-size:1.3rem
}
@media screen and (max-width: 900px) {
  .text-normal
  {
    font-size:1rem
  }
}
@media screen and (max-width: 600px) {
  .text-normal
  {
    font-size:0.75rem
  }
}


@media screen and (max-width: 800px) {
  .main_prez {
    width:100% !important;
    max-width:100% !important;
  }
}


.bouton
{
  border-radius: 100px !important;
  color: #46006f !important;
  border-color: #46006f !important;
  margin: 5px 5px 5px 5px;
  border: 1px solid;
  font-size: 1.2rem !important;
  text-transform: none !important;
}
.bouton:hover
{
  background-color: #46006f!important;
  color: #FFFFFF !important;
}


.bouton_bleu
{
  
  background-color: #46006f!important;
  color: #FFFFFF !important;
  border-radius: 100px !important;
  border-color: #46006f !important;
  margin: 5px 5px 5px 5px;
  border: 1px solid;
  font-size: 1.2rem !important;
  text-transform: none !important;
}
.bouton_bleu:hover
{
  background-color: #FFFFFF!important;
  color: #46006f !important;
}



.bandeau_total
{
  border-radius: 20px 20px 20px 20px !important;
  width: 100%;
  margin: 5px 0px 5px 0px;
  background-color: #46006f !important;
  color : #FFFFFF !important;
}
.bandeau_poste
{
  border-radius: 20px 20px 20px 20px !important;
  width: 100%;
  margin: 5px 0px 5px 0px;
  background-color: #14ebdc !important;
  color : #FFFFFF !important;
}


.div_total
{
  border-radius: 10px 10px 10px 10px !important;
  background-color: #46006f !important;
  color : #FFFFFF !important;
  margin-bottom : 15px;
  font-family: Dosis, sans-serif !important;

}
.div_poste
{
  border-radius: 10px 10px 10px 10px !important;
  background-color: #14ebdc !important;
  color : #FFFFFF !important;
  margin-bottom : 15px;
  font-family: Dosis, sans-serif !important;
}

.total
{
  background-color: #46006f !important;
  color : #FFFFFF !important;
}

.panel
{
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #FFFFFF;
  color: #384B6F !important;
}

.panel_content
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #008cf088;
}

.panel_sousposte_head
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #FFFFFF33 !important;
  margin:5px;
  padding:5px;
}

.panel_sousposte
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #e4d9ea !important;
  margin: 0px 5px 10px 5px;
  width: 100%
}

.panel_saisie
{
  color: #384B6F !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #e4d9ea !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}

.panel_saisie_sp
{
  color: #46006f !important;
  border-radius: 10px 10px 10px 10px !important;
  background-color:  #14ebdc33 !important;
}


.scope_PDF
{
  font-family: Dosis, sans-serif !important;
  font-size:3rem;
}


.panel_PDF
{
  color: #46006f !important;
  border: 1px solid;
  border-color: #46006f !important;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0px 20px 20px 0px;
}

.trait_PDF
{
  width: 100%;
  padding: 2px;
  border-bottom: 1px solid;
  border-color: #46006f !important;
}

.saisie
{
  background-color:  #FFFFFF88 !important;
}

.top-header-flex {
  justify-content: center;
  background-color: #FFFFFF;
}

.bottom-header-flex {
  background-color: #46006f;
}

.titre-page {
  margin-top: 15px;
}
.legende {
  font-size : 12px ;
  display : block ;
}
.fntp-div {
  margin-top: 8px;
  margin-right: 5px;
}
.fntp-container {
  padding-left:   0px !important;
  padding-right:  0px !important;
}

.rotate_chevron_down {
  transition: transform 0.5s;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  }

.rotate_chevron_up {
  transition: transform 0.5s;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  }


.v-expansion-panel::after
{
  border-radius: 10px !important;
  padding: 10px !important;
}

</style>
